export default {
  primary: '#143756',
  secondary: '#136acd',
  white: '#fff',
  black: '#000',
  primaryText: '#536b79',
  grey: '#ddd'
}

export const vendorTheme = {
  primary: '#136acd',
  secondary: '#143756',
  white: '#fff',
  black: '#000',
  primaryText: '#536b79',
  grey: '#ddd'
}
