import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
//i18n
import { withNamespaces } from "react-i18next";
import { SiYoutube } from 'react-icons/si';
import { connect } from "react-redux";
import { changeLayout, changeLayoutWidth, changeSidebarTheme, changeSidebarType, changePreloader } from "../../store/actions";

class SidebarContent extends Component {
  constructor(props) {
    const similarPath = Cookies.get("similarPath");
    var rolesAccess = Cookies.get("access") ? JSON.parse(Cookies.get("access")) : [];

    super(props);
    this.state = { clicked: false, rolesAccess: rolesAccess, clicked2: false, similarPath : similarPath};
  }

  componentDidMount() {
    this.initMenu();
    this.setState({ ...this.state, clicked: false, clicked2: false });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  // initMenu() {
  //   new MetisMenu("#side-menu");

  //   var matchingMenuItem = null;
  //   var ul = document.getElementById("side-menu");
  //   console.log("ul list", ul);
  //   var items = ul.getElementsByTagName("a");
  //   console.log("items", items);
  //   for (var i = 0; i < items.length; ++i) {
  //     if (this.props.location.pathname === items[i].pathname) {
  //       matchingMenuItem = items[i];
  //       console.log("matchingMenuItem", matchingMenuItem);
  //       break;
  //     }
  //   }
  //   if (matchingMenuItem) {
  //     this.activateParentDropdown(matchingMenuItem);
  //   }
  // }

  initMenu() {
    new MetisMenu("#side-menu");
    console.log("UpdatePath", this.state.similarPath)
    console.log("locationPath", this.props.location.pathname)
    console.log("rolesAccess", this.state.rolesAccess)

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (
        this.props.location.pathname === items[i].pathname ||
        (((this.props.location.pathname.startsWith("/adminPanel/ViewUser") || this.props.location.pathname.startsWith("/adminPanel/RatingAndReview")) && this.state.rolesAccess.includes("user")) && this.state.similarPath == "/adminPanel/user-management" && items[i].pathname === "/adminPanel/user-management") ||
        ((
          (this.props.location.pathname.startsWith("/adminPanel/driver-view") || this.props.location.pathname.startsWith("/adminPanel/RatingAndReview") || this.props.location.pathname.startsWith("/adminPanel/DocumentExpire") || this.props.location.pathname.startsWith("/adminPanel/ViewDriverHistory")) &&
          this.state.similarPath == "/adminPanel/ApprovedDriver" &&
          (this.state.rolesAccess.includes("approved_driver") || this.state.rolesAccess.includes("All"))) &&
          items[i].pathname === "/adminPanel/ApprovedDriver"
        ) ||
        ((
          (this.props.location.pathname.startsWith("/adminPanel/driver-view") || this.props.location.pathname.startsWith("/adminPanel/ViewDriverHistory")) &&
          this.state.similarPath == "/adminPanel/PendingDriver" &&
          (this.state.rolesAccess.includes("pending_driver") || this.state.rolesAccess.includes("All"))) &&
          items[i].pathname === "/adminPanel/PendingDriver"
        ) ||
        ((
          (this.props.location.pathname.startsWith("/adminPanel/driver-view") || this.props.location.pathname.startsWith("/adminPanel/ViewDriverHistory")) &&
          this.state.similarPath == "/adminPanel/DisapprovedDriver" &&
          (this.state.rolesAccess.includes("disapproved_driver") || this.state.rolesAccess.includes("All"))) &&
          items[i].pathname === "/adminPanel/DisapprovedDriver"
        ) ||
        ((
          (this.props.location.pathname.startsWith("/adminPanel/driver-view") || this.props.location.pathname.startsWith("/adminPanel/ViewDriverHistory")) &&
          this.state.similarPath == "/adminPanel/CancelDriver" &&
          (this.state.rolesAccess.includes("cancel_driver") || this.state.rolesAccess.includes("All"))) &&
          items[i].pathname === "/adminPanel/CancelDriver"
        ) ||
        (this.props.location.pathname.startsWith("/adminPanel/BookingDetails") && this.state.similarPath == "/adminPanel/Booking_Management" && items[i].pathname === "adminPanel/Booking_Management")
      ) {
        matchingMenuItem = items[i];
        Cookies.set("similarPath", matchingMenuItem.pathname, {
          expires: 365,
        });
        console.log("matchingMenuItem", matchingMenuItem.pathname);
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;
      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          console.log("parent4", parent4);
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Menu")}</li>
            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("dashboard")) && (
              <li>
                <NavLink
                  to="/adminPanel/dashboard"
                  className="waves-effect"
                >
                  <i className="ri-dashboard-line"></i>
                  <span
                    style={{
                      borderBottom: this.props.history.location.pathname == "/adminPanel/dashboard" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Dashboard")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("banner")) && (
              <li>
                <NavLink
                  to="/adminPanel/BannerManagement"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-image-2-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/BannerManagement" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Banner")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("user")) && (
              <li>
                <NavLink
                  to="/adminPanel/user-management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i className="ri-user-3-fill"></i>{" "}
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname.startsWith("/adminPanel/user-management") ||
                        this.props.history.location.pathname.startsWith("/adminPanel/ViewUser") ||
                        (this.props.history.location.pathname.startsWith("/adminPanel/RatingAndReview") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/user-management") ||
                        (this.props.history.location.pathname.startsWith("/adminPanel/BookingDetails") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/user-management")
                        ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage User")}
                  </span>
                </NavLink>
              </li>
            )}

            {/* driver lsiting */}
            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("approved_driver") || this.state.rolesAccess.includes("pending_driver") || this.state.rolesAccess.includes("disapproved_driver") || this.state.rolesAccess.includes("cancel_driver")) && (
              <li>
                <NavLink
                  to="/Driver-management"
                  className="waves-effect submenu"
                  onClick={() => {
                    this.setState({ ...this.state, clicked2: !this.state.clicked2, clicked: false });
                    console.log(this.state.clicked2, ":::::::");
                  }}
                >
                  <i class="ri-user-2-fill"></i>

                  <span className="ml-1">{this.props.t("Manage Driver")}</span>
                  {this.state.clicked2 === false ? (
                    <i className="ri-arrow-right-s-fill"></i>
                  ) : (
                    <i className="ri-arrow-down-s-fill"></i>
                  )}
                </NavLink>
                {
                  <ul style={{ backgroundColor: "#4d9aff", paddingLeft: "10px" }}>
                    {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("approved_driver")) && (
                      <li>
                        <NavLink to="/adminPanel/ApprovedDriver" className="waves-effect">
                          <i class="ri-check-line"></i>
                          <span
                            className="ml-1"
                            style={{
                              borderBottom:
                                this.props.history.location.pathname.startsWith("/adminPanel/ApprovedDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("approved_driver")) ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/ApprovedDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/RatingAndReview") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/ApprovedDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/DocumentExpire") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/ApprovedDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/ViewDriverHistory") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/ApprovedDriver")
                                ? "2px solid #fff" : "",
                            }}            
                          >
                            {this.props.t("Approved Driver")}
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("pending_driver")) && (
                      <li>
                        <NavLink to="/adminPanel/PendingDriver" className="waves-effect">
                          <i class="ri-error-warning-line"></i>
                          <span
                            className="ml-1"
                            style={{
                              borderBottom:
                                this.props.history.location.pathname.startsWith("/adminPanel/PendingDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("pending_driver")) ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/PendingDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/ViewDriverHistory") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/PendingDriver")
                                ? "2px solid #fff" : "",
                            }}            
                          >
                            {this.props.t("Pending Driver")}
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("disapproved_driver")) && (
                      <li>
                        <NavLink to="/adminPanel/DisapprovedDriver" className="waves-effect">
                          <i class="ri-close-line"></i>
                          <span
                            className="ml-1"
                            style={{
                              borderBottom:
                                this.props.history.location.pathname.startsWith("/adminPanel/DisapprovedDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("disapproved_driver")) ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/DisapprovedDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/ViewDriverHistory") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/DisapprovedDriver")
                                ? "2px solid #fff" : "",
                            }}            
                          >
                            {this.props.t("Disapproved Driver")}
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("cancel_driver")) && (
                      <li>
                        <NavLink to="/adminPanel/CancelDriver" className="waves-effect">
                          <i class="ri-close-circle-fill"></i>
                          <span
                            className="ml-1"
                            style={{
                              borderBottom:
                                this.props.history.location.pathname.startsWith("/adminPanel/CancelDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("cancel_driver")) ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/driver-view") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/CancelDriver") ||
                                (this.props.history.location.pathname.startsWith("/adminPanel/ViewDriverHistory") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/CancelDriver")
                                ? "2px solid #fff" : "",
                            }}            
                          >
                            {this.props.t("Cancelled Driver")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                }
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("tip")) && (
              <li>
                <NavLink
                  to="/adminPanel/Tip_Management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-money-dollar-circle-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/Tip_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Tip")}
                  </span>
                </NavLink>
              </li>
            )}

            {/* service */}
            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("Manage Service Fare") || this.state.rolesAccess.includes("fare_single_taxi") || this.state.rolesAccess.includes("fare_designated_driver")
            || this.state.rolesAccess.includes("fare_pet_service") || this.state.rolesAccess.includes("fare_package_service")) && (
              <li>
                <NavLink
                  to="/Service-management"
                  className="waves-effect submenu"
                  onClick={() => {
                    this.setState({ ...this.state, clicked: !this.state.clicked, clicked2: false });
                    console.log(this.state.clicked, ":::::::");
                  }}
                >
                  <i className="ri-building-line"></i>

                  <span className="ml-1">{this.props.t("Manage Service Fare")}</span>
                  {this.state.clicked === false ? (
                    <i className="ri-arrow-right-s-fill"></i>
                  ) : (
                    <i className="ri-arrow-down-s-fill"></i>
                  )}
                </NavLink>
                <ul style={{ backgroundColor: "#4d9aff", paddingLeft: "10px" }}>
                  {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("fare_single_taxi")) && (
                    <li>
                      <NavLink to="/adminPanel/TaxiSingleManagement" className="waves-effect">
                        <i class="ri-taxi-line"></i>

                        <span
                          className="ml-1"
                          style={{
                            borderBottom:
                              this.props.history.location.pathname == "/adminPanel/TaxiSingleManagement" ? "2px solid #fff" : "",
                          }}
                        >
                          {this.props.t("Single Taxi")}
                        </span>
                      </NavLink>
                    </li>
                  )}

                  {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("fare_designated_driver")) && (
                    <li>
                      <NavLink to="/adminPanel/ManageDesignatedDriver" className="waves-effect">
                        <i className="ri-account-circle-line"></i>
                        <span
                          className="ml-1"
                          style={{
                            borderBottom:
                              this.props.history.location.pathname == "/adminPanel/ManageDesignatedDriver"
                                ? "2px solid #fff"
                                : "",
                          }}
                        >
                          {this.props.t("Designated Driver")}
                        </span>
                      </NavLink>
                    </li>
                  )}

                  {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("fare_pet_service")) && (
                    <li>
                      <NavLink to="/adminPanel/PetCategoryManagement" className="waves-effect">
                        <i class="ri-bear-smile-fill"></i>
                        <span
                          className="ml-1"
                          style={{
                            borderBottom:
                              this.props.history.location.pathname == "/adminPanel/PetCategoryManagement" ? "2px solid #fff" : "",
                          }}
                        >
                          {this.props.t("Pet Service")}
                        </span>
                      </NavLink>
                    </li>
                  )}

                  {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("fare_package_service")) && (
                    <li>
                      <NavLink to="/adminPanel/PackageManagement" className="waves-effect">
                        <i class="ri-red-packet-fill"></i>
                        <span
                          className="ml-1"
                          style={{
                            borderBottom:
                              this.props.history.location.pathname == "/adminPanel/PackageManagement" ? "2px solid #fff" : "",
                          }}
                        >
                          {this.props.t("Package Service")}
                        </span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {/* Manage Happy Money */}
            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("happy_money")) && (
              <li>
                <NavLink
                  to="/adminPanel/Happy_Money_Management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-money-dollar-circle-fill"></i>{" "}
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/Happy_Money_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Happy Money")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("mileage")) && (
              <li>
                <NavLink to="/adminPanel/MilesManagement" className="waves-effect">
                  <i class="ri-coins-line"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/MilesManagement" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Mileage")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("booking")) && (
              <li>
                <NavLink
                  to="/adminPanel/Booking_Management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-book-open-fill"></i>{" "}
                  <span
                    // style={{
                    //   borderBottom:
                    //     this.props.history.location.pathname == "/adminPanel/Booking_Management" ? "2px solid #fff" : "",
                    // }}
                    style={{
                      borderBottom:
                        this.props.history.location.pathname.startsWith("/adminPanel/Booking_Management") ||
                        (this.props.history.location.pathname.startsWith("/adminPanel/BookingDetails") && this.state.rolesAccess.includes("booking")) ||
                        (this.props.history.location.pathname.startsWith("/adminPanel/BookingDetails") && this.state.rolesAccess.includes("All") && this.state.similarPath == "/adminPanel/Booking_Management")
                        ? "2px solid #fff" : "",
                    }}       
                    className="ml-1"
                  >
                    {this.props.t("Manage Booking")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("scheduled_ride")) && (
              <li>
                <NavLink
                  to="/adminPanel/ScheduledRidesManagement"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-taxi-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/ScheduledRidesManagement" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Scheduled Rides")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("gift_card")) && (
              <li>
                <NavLink to="/adminPanel/GiftCardManagement" className="waves-effect">
                  <i class="ri-gift-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/GiftCardManagement" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Gift Card")}
                  </span>
                </NavLink>
              </li>
            )}

            {/* Manage Promocode */}
            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("default_promo_code") || this.state.rolesAccess.includes("other_promo_code")) && (
              <li>
                <NavLink
                  to=""
                  className="waves-effect submenu"
                  onClick={() => {
                    this.setState({ ...this.state, clicked2: !this.state.clicked2, clicked: false });
                    console.log(this.state.clicked2, ":::::::");
                  }}
                >
                  <i class="ri-coupon-3-fill"></i>{" "}

                  <span className="ml-1">{this.props.t("Manage Promocode")}</span>
                  {this.state.clicked2 === false ? (
                    <i className="ri-arrow-right-s-fill"></i>
                  ) : (
                    <i className="ri-arrow-down-s-fill"></i>
                  )}
                </NavLink>
                {
                  <ul style={{ backgroundColor: "#4d9aff", paddingLeft: "10px" }}>
                    {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("default_promo_code")) && (
                      <li>
                        <NavLink to="/adminPanel/PromoCode_Management" className="waves-effect">
                          <i class="ri-coupon-3-fill"></i>
                          <span
                            className="ml-1"
                            style={{
                              borderBottom:
                                this.props.history.location.pathname == "/adminPanel/PromoCode_Management" ? "2px solid #fff" : "",
                            }}
                          >
                            {this.props.t("Set Default Promo")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                    {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("other_promo_code")) && (
                      <li>
                        <NavLink to="/adminPanel/Other_PromoCode_Management" className="waves-effect">
                          <i class="ri-coupon-3-fill"></i>
                          <span
                            className="ml-1"
                            style={{
                              borderBottom:
                                this.props.history.location.pathname == "/adminPanel/Other_PromoCode_Management" ? "2px solid #fff" : "",
                            }}
                          >
                            {this.props.t("Other Promo Codes")}
                          </span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                }
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("cancellation_reason")) && (
              <li>
                <NavLink to="/adminPanel/CancellationReason" className="waves-effect">
                  <i class="ri-close-circle-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/CancellationReason" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Cancellation Reason")}
                  </span>
                </NavLink>
              </li>
            )}

            {/* Videos */}
            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("videos")) && (
              <li>
                <NavLink
                  to="/adminPanel/Video_Management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-youtube-fill ri-2x"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/Video_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Educational Videos")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("sub_admin")) && (
              <li>
                <NavLink
                  to="/adminPanel/SubAdmin_Management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i className="ri-admin-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/SubAdmin_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage SubAdmin")}
                  </span>
                </NavLink>
              </li>
            )}

            {/* Office Management */}
            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("office")) && (
              <li>
                <NavLink to="/adminPanel/Office_Management" className="waves-effect">
                <i className="ri-building-line"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/Office_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Office")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("notification")) && (
              <li>
                <NavLink
                  to="/adminPanel/Notification_Management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-notification-2-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/Notification_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Notification")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("faq")) && (
              <li>
                <NavLink
                  to="/adminPanel/FAQ_Management"
                  className="waves-effect"
                  style={{
                    fontSize: 16,
                    display: "flex",
                    alignItems: "flex-start",
                  }}
                >
                  <i class="ri-question-answer-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/FAQ_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage FAQ")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("settings")) && (
              <li>
                <NavLink to="/adminPanel/Content_Management" className="waves-effect">
                  <i class="ri-settings-5-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/Content_Management" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Settings")}
                  </span>
                </NavLink>
              </li>
            )}

            {(this.state.rolesAccess.includes("All") || this.state.rolesAccess.includes("tier")) && (
              <li>
                <NavLink to="/adminPanel/ManageTier" className="waves-effect">
                  <i class="ri-close-circle-fill"></i>
                  <span
                    style={{
                      borderBottom:
                        this.props.history.location.pathname == "/adminPanel/ManageTier" ? "2px solid #fff" : "",
                    }}
                    className="ml-1"
                  >
                    {this.props.t("Manage Tier")}
                  </span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
