import React, { Component, uesState } from "react";
import axios from "../../axios";
import { Row, Col, Input, Button, Alert, Container, Label, FormGroup } from "reactstrap";

import Cookies from "js-cookie";
import { toast } from "react-toastify";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { checkLogin, apiError } from "../../store/actions";

import happyTaxi from "../../assets/images/logo/happyTaxi3.png";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { fetchToken } from "../../utils/firebase";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            showpass: true,
            deviceToken: null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    handleShow() {
        this.setState({ showpass: !this.state.showpass });
    }

    async componentDidMount() {
        // try {
        //     const deviceToken = await new Promise((resolve, reject) => {
        //         fetchToken(resolve, reject);
        //     });
        //     this.setState({ deviceToken });
        // } catch (error) {
        //     console.error("Error fetching device token:", error);
        // }

        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
    }

    async handleSubmit(event, values) {
        let requestData = {
            inputUserName: this.props.checkLogin(values, this.props.history).payload.user.username,
            inputUserPassword: this.props.checkLogin(values, this.props.history).payload.user.password,
        };

        try {
            let { data } = await axios.post("/login", {
                email: requestData.inputUserName,
                password: requestData.inputUserPassword,
                // deviceToken: this.state.deviceToken || "Device_token",
                deviceToken: "Device_token",
                deviceId: "2",
            });

            console.log("LoginApiData",data)

            Cookies.set("admin_access_token", data.token?.accessToken, { expires: 365 });
            Cookies.set("SubAdmin", data.data?.isInspector, { expires: 365 });
            Cookies.set("userType", data.data?.userType, { expires: 365 });
            Cookies.set("username", data.data?.username, { expires: 365 });
            Cookies.set("profileImage", data.data?.profileImage, { expires: 365 });

            // let accessArray = [];
            // data.data.accessModule.map((ele, i) => {
            //     if (ele === "banner") {
            //         accessArray.push("Manage Banner");
            //     }
            //     if (ele === "user") {
            //         accessArray.push("Manage User");
            //     }
            //     if (ele === "approved_driver") {
            //         accessArray.push("Approved Driver");
            //     }
            //     if (ele === "pending_driver") {
            //         accessArray.push("Pending Driver");
            //     }
            //     if (ele === "disapproved_driver") {
            //         accessArray.push("Disapproved Driver");
            //     }
            //     if (ele === "tip") {
            //         accessArray.push("Manage Tip");
            //     }
            //     if (ele === "fare_single_taxi") {
            //         accessArray.push("Single Taxi");
            //     }
            //     if (ele === "fare_designated_driver") {
            //         accessArray.push("Designated Driver");
            //     }
            //     if (ele === "fare_pet_service") {
            //         accessArray.push("Pet Service");
            //     }
            //     if (ele === "fare_package_service") {
            //         accessArray.push("Package Service");
            //     }
            //     if (ele === "happy_money") {
            //         accessArray.push("Manage Happy Money");
            //     }
            //     if (ele === "booking") {
            //         accessArray.push("Manage Booking");
            //     }
            //     if (ele === "scheduled_ride") {
            //         accessArray.push("Manage Scheduled Rides");
            //     }
            //     if (ele === "mileage") {
            //         accessArray.push("Manage Mileage");
            //     }
            //     if (ele === "gift_card") {
            //         accessArray.push("Manage Gift Card");
            //     }
            //     if (ele === "default_promo_code") {
            //         accessArray.push("Set Default Promo");
            //     }
            //     if (ele === "other_promo_code") {
            //         accessArray.push("Other Promo Codes");
            //     }
            //     if (ele === "cancellation_reason") {
            //         accessArray.push("Manage Cancellation Reason");
            //     }
            //     if (ele === "videos") {
            //         accessArray.push("Manage Educational Videos");
            //     }
            //     if (ele === "notification") {
            //         accessArray.push("Manage Notification");
            //     }
            //     if (ele === "faq") {
            //         accessArray.push("Manage FAQ");
            //     }
            //     if (ele === "settings") {
            //         accessArray.push("Manage Settings");
            //     }
            //     if (ele === "tier") {
            //         accessArray.push("Manage Tier");
            //     }
            //     if (ele === "All") {
            //         accessArray.push("All");
            //     }
            // });

            // Cookies.set("access", JSON.stringify(accessArray), {
            //     expires: 365,
            // });

            Cookies.remove("access");
            Cookies.set("access", JSON.stringify(data.data.accessModule), { expires: 365 });
            Cookies.set("email", JSON.stringify(data.data.email), { expires: 365 });

            if (data.data.isBlock === "block") {
                alert("It looks like your account has been blocked. Please contact your admin to unblock it");
                Cookies.remove("admin_access_token");
                Cookies.remove("userType");
                Cookies.remove("username");
                Cookies.remove("profileImage");
                // this.props.history.push("/adminPanel/login");
                window.location.href = "/adminPanel/login";
            } else {
                // this.props.history.push("/adminPanel/dashboard");
                const Access = Cookies.get("access");

                if (data.data.userType === 'sub_admin') {
                    if ((data.data.accessModule && data.data.accessModule.length > 0) || (Access && Access.length > 0)) {
                        const firstAccessibleModule = (data.data.accessModule[0] || Access[0]);
                        let redirectPath = '';

                        switch (firstAccessibleModule) {
                            case 'dashboard':
                                redirectPath = '/adminPanel/dashboard';
                                break;
                            case 'banner':
                                redirectPath = '/adminPanel/BannerManagement';
                                break;
                            case 'user':
                                redirectPath = '/adminPanel/user-management';
                                break;
                            case 'approved_driver':
                                redirectPath = '/adminPanel/ApprovedDriver';
                                break;
                            case 'pending_driver':
                                redirectPath = '/adminPanel/PendingDriver';
                                break;
                            case 'disapproved_driver':
                                redirectPath = '/adminPanel/DisapprovedDriver';
                                break;
                            case 'tip':
                                redirectPath = '/adminPanel/Tip_Management';
                                break;
                            case 'fare_single_taxi':
                                redirectPath = '/adminPanel/TaxiSingleManagement';
                                break;
                            case 'fare_designated_driver':
                                redirectPath = '/adminPanel/ManageDesignatedDriver';
                                break;
                            case 'fare_pet_service':
                                redirectPath = '/adminPanel/PetCategoryManagement';
                                break;
                            case 'fare_package_service':
                                redirectPath = '/adminPanel/PackageManagement';
                                break;
                            case 'happy_money':
                                redirectPath = '/adminPanel/Happy_Money_Management';
                                break;
                            case 'booking':
                                redirectPath = '/adminPanel/Booking_Management';
                                break;
                            case 'scheduled_ride':
                                redirectPath = '/adminPanel/ScheduledRidesManagement';
                                break;
                            case 'mileage':
                                redirectPath = '/adminPanel/MilesManagement';
                                break;
                            case 'gift_card':
                                redirectPath = '/adminPanel/GiftCardManagement';
                                break;
                            case 'default_promo_code':
                                redirectPath = '/adminPanel/PromoCode_Management';
                                break;
                            case 'other_promo_code':
                                redirectPath = '/adminPanel/Other_PromoCode_Management';
                                break;
                            case 'cancellation_reason':
                                redirectPath = '/adminPanel/CancellationReason';
                                break;
                            case 'videos':
                                redirectPath = '/adminPanel/Video_Management';
                                break;
                            case 'notification':
                                redirectPath = '/adminPanel/Notification_Management';
                                break;
                            case 'faq':
                                redirectPath = '/adminPanel/FAQ_Management';
                                break;
                            case 'settings':
                                redirectPath = '/adminPanel/Content_Management';
                                break;
                            case 'tier':
                                redirectPath = '/adminPanel/ManageTier';
                                break;
                            case 'office':
                                redirectPath = '/adminPanel/Office_Management';
                                break;
                            default:
                                break;
                        }
        
                        this.props.history.push(redirectPath);
                    } else {
                        toast.error('No accessible modules found for this user.', {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                } else {
                    this.props.history.push('/adminPanel/dashboard');
                }
            }
        } catch (error) {
            if (error.response.data.errors[0].msg === "Your account has been blocked,   contact admin") {
                toast.error("You are blocked by admin", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error(`Please Enter the correct Credentials`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }

    render() {
        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/adminPanel/">
                        <i className="mdi mdi-home-variant h2 text-white"></i>
                    </Link>
                </div>

                <div>
                    <Container fluid className="p-0">
                        <Row className="no-gutters">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/adminPanel/" className="logo">
                                                                <img src={happyTaxi} height="50" alt="logo" />
                                                            </Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                        <p className="text-muted">Sign in to continue to Happy Taxi.</p>
                                                    </div>
                                                    <div className="p-2 mt-5">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit}>
                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="username">Email</Label>
                                                                <AvField
                                                                    name="username"
                                                                    value={this.state.username}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="username"
                                                                    validate={{ email: true, required: true }}
                                                                    placeholder="Enter Email"
                                                                />
                                                            </FormGroup>

                                                            <FormGroup className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Password</Label>
                                                                <div className="d-flex align-items-center">
                                                                    {" "}
                                                                    <div className="flex-fill">
                                                                        <AvField
                                                                            name="password"
                                                                            value={this.state.password}
                                                                            type={this.state.showpass ? "password" : "text"}
                                                                            className="form-control "
                                                                            validate={{
                                                                                required: true,
                                                                            }}
                                                                            id="userpassword"
                                                                            placeholder="Enter password"
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            right: 10,
                                                                            top: 20,
                                                                        }}
                                                                    >
                                                                        {this.state.showpass ? (
                                                                            <VisibilityOff onClick={this.handleShow} />
                                                                        ) : (
                                                                            <Visibility onClick={this.handleShow} />
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </FormGroup>

                                                            <div className="custom-control custom-checkbox">
                                                                <Input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                                <Label className="custom-control-label" htmlFor="customControlInline">
                                                                    Remember me
                                                                </Label>
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button
                                                                    style={{
                                                                        backgroundColor: "#2765B3",
                                                                        color: "#fff",
                                                                    }}
                                                                    className="w-md waves-effect waves-light"
                                                                    type="submit"
                                                                >
                                                                    Log In
                                                                </Button>
                                                            </div>

                                                            {/* <div className="mt-4 text-center">
                                                                <Link to="/adminPanel/forgot-password" className="text-muted">
                                                                    <i className="mdi mdi-lock mr-1"></i> Forgot your password?
                                                                </Link>
                                                            </div> */}
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <p>© 2022 HappyTaxi.</p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg">
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { loginError } = state.Login;
    return { loginError };
};

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));
