import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { API_URL } from "./statics/constants";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import Routes
import { authProtectedRoutes, publicRoutes, dynamicRoutes } from "./routes/index.js";
import AppRoute from "./routes/route";
// import AppRoute,{PrivateRoute,PublicOnlyRoute} from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";

// import { get } from "lodash";
// import { toast } from "react-toastify";
// import * as actionTypes from "./store/NewRedux/actions.js";
// import { app, fetchToken, onMessageListener } from "./utils/firebase.js";

const App = (props) => {
    // const { deviceToken, setDeviceToken } = props;
    // const [notification, setNotification] = useState({ title: "", body: "" });

    axios.defaults.baseURL = API_URL;

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    const Layout = getLayout();

    // start her for firebase notification
    // useEffect(() => {
    //     fetchToken(setDeviceToken);
    // }, []);

    // onMessageListener().then((payload) => {
    //     console.log("Payload", payload)
    //     setNotification({
    //         title: get(payload, "notification.title", ""),
    //         body: get(payload, "notification.body", ""),
    //     });

    //     toast(<Msg title={get(payload, "notification.title", "")} body={get(payload, "notification.body", "")} toastStyle={{ backgroundColor: "#41C658" }} />, {
    //         style: { background: '#28C54E' },
    //     });
    // }).catch((err) => {
    //     console.log("failed: ", err)
    // });

    // const Msg = ({ title, body }) => (
    //     <>
    //         <p style={{ color: "#000000", marginBottom:"0" }}>{title}</p>
    //         <p style={{ color: "#000000", marginBottom:"0"  }}>{body}</p>
    //     </>
    // );

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {publicRoutes.map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            layout={NonAuthLayout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={false}
                            title={route.title}
                        />
                    ))}

                    {dynamicRoutes().map((route, idx) => (
                        <AppRoute
                            path={route.path}
                            layout={Layout}
                            component={route.component}
                            key={idx}
                            isAuthProtected={true}
                            title={(route.title)}
                        />
                    ))}
                </Switch>
            </Router>
            <ToastContainer />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        layout: state.Layout,
        deviceToken: state.DeviceToken.deviceToken,
    };
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         setDeviceToken: (updatedValue) => {
//             dispatch({
//                 type: actionTypes.DEVICE_TOKEN,
//                 updateDeviceToken: updatedValue,
//             });
//         },
//     };
// };

export default connect(mapStateToProps)(App);
