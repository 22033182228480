import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import { Paper } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import KErrorMessage from "./KErrorMessage";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "../../axios";
import { toast } from "react-toastify";
import { get } from "lodash";
import Cookies from "js-cookie";
import { handleImageUpload } from "../../services/upload-files-service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
import { Description } from "@material-ui/icons";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import { useUploadImage } from "../../services/Imagecustomhook";
import Loader from "../../components/Loader";
import Spinner from "../ServiceManagement/Spinner";
import WallpaperIcon from "@material-ui/icons/Wallpaper";
import Axios from "axios";
import Swal from "sweetalert2";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { ImageUploadUrl } from "../../statics/constants";
import { developmentDeleteUrl } from "../../statics/constants";
import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  table: {
    minWidth: 650,
  },
  textMiddle: {
    verticalAlign: "middle !important",
  },
  iconMargin: {
    margin: "0.5rem",
    color: "#696969",
    backgroundColor: "#fff",
  },
  iconcolor: {
    margin: "0.5rem",
    color: "#fff",
    backgroundColor: "#0294b3 !important",
  },
  headingButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "10px",
  },
  headingAlignment: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // padding: "0 2rem 0 2rem"
    alignItems: "center",
    flexWrap: "wrap",
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      flexDirection: "column",
      width: "100%",
      gap: "1rem",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  addNewCategory: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      flexDirection: "column",
      width: "100%",
      gap: "1rem",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  addNewCategoryHeading: {
    textAlign: "center",
    flex: 1,
    paddingBottom: "0 !important",
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      flexDirection: "column",
      width: "100%",
      gap: "1rem",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  MarginControl: {
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      margin: "0 !important",
    },
  },
  Marginbutton: {
    margin: "0.5rem",
  },
  container: {
    maxHeight: "58vh",
  },
  paperPaddingRightLeft: {
    padding: "0rem 1rem",
  },
}));

const AddEdit_SubAdmin = (props) => {
  const classes = useStyles();
    
  const {
    location: { state },
  } = props;

  const { isLoading, isSuccess, uploadForm, progress } = useUploadImage(`${ImageUploadUrl}/fileUpload`);
  const [spinnerDisplay, setSpinnerDisplay] = useState(false);
  const [imageDelete, setImageDelete] = useState(false);
  const [dialCode, setdialCode] = useState(!state ? "" : state.countryCode.replace(`+`, ""));

  const fileRef = useRef(null);
  const fileRef1 = useRef(null);
  const SUPPORTED_FORMATS = ["image/jpeg", "image/png", "image/jpg"];

  const Panes = [
    { panelName: "Select All" },
    { panelName: "Manage Banner" },
    { panelName: "Manage User" },
    { panelName: "Approved Driver" },
    { panelName: "Pending Driver" },
    { panelName: "Disapproved Driver" },
    { panelName: "Manage Tip" },
    { panelName: "Single Taxi" },
    { panelName: "Designated Driver" },
    { panelName: "Pet Service" },
    { panelName: "Package Service" },
    { panelName: "Manage Happy Money" },
    { panelName: "Manage Booking" },
    { panelName: "Manage Scheduled Rides" },
    { panelName: "Manage Mileage" },
    { panelName: "Manage Gift Card" },
    { panelName: "Set Default Promo" },
    { panelName: "Other Promo Codes" },
    { panelName: "Manage Cancellation Reason" },
    { panelName: "Manage Educational Videos" },
    { panelName: "Manage Notification" },
    { panelName: "Manage FAQ" },
    { panelName: "Manage Settings" },
    { panelName: "Manage Tier" },
  ];

  const titleMapping = {
    "banner": "Manage Banner",
    "user": "Manage User",
    "approved_driver": "Approved Driver",
    "pending_driver": "Pending Driver",
    "disapproved_driver": "Disapproved Driver",
    "tip": "Manage Tip",
    "fare_single_taxi": "Single Taxi",
    "fare_designated_driver": "Designated Driver",
    "fare_pet_service": "Pet Service",
    "fare_package_service": "Package Service",
    "happy_money": "Manage Happy Money",
    "booking": "Manage Booking",
    "scheduled_ride": "Manage Scheduled Rides",
    "mileage": "Manage Mileage",
    "gift_card": "Manage Gift Card",
    "default_promo_code": "Set Default Promo",
    "other_promo_code": "Other Promo Codes",
    "cancellation_reason": "Manage Cancellation Reason",
    "videos": "Manage Educational Videos",
    "notification": "Manage Notification",
    "faq": "Manage FAQ",
    "settings": "Manage Settings",
    "tier": "Manage Tier"
  };

  var FinalData;
  const token = Cookies.get("admin_access_token");

  const [panesData, setPanesData] = useState(Panes);
  const [profileImagepath, setProfileImagepath] = useState("");
  const [documentPath1, setDocumentpath1] = useState("");
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState(Panes);
  const [isInspector, setIsInspector] = useState(state?.isInspector || false);
  const [PanesDataFinal, setPanesDataFinal] = useState(state?.accessModule || []);

  useEffect(() => {
    if (isInspector) {
      setDropdownOptions([{ panelName: "Pending Driver" }]);
      setSelectedTitles(["Pending Driver"]);
    } else {
      setDropdownOptions(Panes);
    }
  }, [isInspector]);

  useEffect(() => {
    const initialSelectedTitles = (state?.accessModule || []).map((module) => {
      return titleMapping[module] || '';
    }).filter(Boolean);
    setSelectedTitles(initialSelectedTitles);
  }, [state]);

  useEffect(() => {
    const filteredValues = selectedTitles.map((title) => {
      return Object.keys(titleMapping).find(key => titleMapping[key] === title);
    }).filter(Boolean);
    setPanesDataFinal(filteredValues);
  }, [selectedTitles, isInspector]);

  const handleCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsInspector(checked);
    if (checked) {
      setSelectedTitles(["Pending Driver"]);
    } else {
      setSelectedTitles(selectedTitles.filter(title => title !== "Pending Driver"));
    }
  };

  const handleSelectChange = (selectedOptions) => {
    const selectedTitlesValues = selectedOptions ? selectedOptions.map(option => option.value) : [];

    if (selectedOptions && selectedOptions.some(option => option.value === "Select All")) {
      const allTitles = Panes.map(pane => pane.panelName).slice(1);
      setSelectedTitles(allTitles);
      setIsInspector(false); // Disable isInspector when "Select All" is chosen
    } else {
      setSelectedTitles(selectedTitlesValues);

      if (selectedTitlesValues.includes("Pending Driver") && selectedTitlesValues.length == 1) {
        setIsInspector(true);
      } else {
        setIsInspector(false);
      }
    }

    const filteredValues = selectedTitlesValues.map((title) => {
      return Object.keys(titleMapping).find(key => titleMapping[key] === title);
    }).filter(Boolean);
    setPanesDataFinal(filteredValues);
  };

  //Validation Schema
  const validationSchema = yup.object({
    name: yup.string().min(3, "Minimum 3 characters").max(20, "Maximum 20 characters").required("Name is required!"),
    email: yup.string().email("Invalid email format").required("Email is required!"),
    phone: yup.string().min(4, "Minimum 4 digits required!").max(16, "Maximum 16 digits required!").required("Phone is required!"),
  });  

  // ADDING NEW SUB-ADMIN
  const addNewSubAdmin = async (values) => {
    console.log("submitValue",values)
    try {
      let profileimageurl = profileImagepath;
      let documentimageurl = documentPath1;
      let phone = values.phone.replace(`${dialCode}`, "");
      
      const { data } = await axios.post("/private/subadmin", {
        username: values.name,
        email: values.email,
        phone: phone,
        profileImage: profileimageurl,
        countryCode: `+${dialCode}`,
        accessModule: PanesDataFinal,
        isInspector: values.isInspector,
      });

      props.history.push({
        pathname: "/adminPanel/SubAdmin_Management",
      });
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  //Edit SubAdmin
  const EditSubAdmin = async (values) => {
    let profileimageurl;
    try {
      if (state?.profileImage === values.file1) {
        profileimageurl = values.file1;
      } else {
        await DeleteImage();
        profileimageurl = values.file1;
      }
      let phone = values.phone.replace(`${dialCode}`, "");

      const { data } = await axios.put(`/private/subadmin/${state._id}`, {
        username: values.name || state.username,
        email: values.email || state.email,
        phone: phone || state.mobile_number,
        profileImage: profileimageurl,
        countryCode: `+${dialCode}`,
        accessModule: PanesDataFinal,
        isInspector: values.isInspector,
      });
      props.history.push({
        pathname: "/adminPanel/SubAdmin_Management",
      });
      toast.success(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteImage = async () => {
    let imageID = !state ? "" : state.profileImage.split("/")[3];
    console.log(imageID);
    try {
      const { data } = await Axios.get(`${developmentDeleteUrl}/fileDelete/${imageID}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleBackButton = () => {
    props.history.push({
      pathname: "/adminPanel/SubAdmin_Management",
    });
    // Swal.fire({
    //   showClass: {
    //     popup: "animate__animated animate__fadeInDown",
    //   },
    //   hideClass: {
    //     popup: "animate__animated animate__fadeOutUp",
    //   },
    //   title: "Do you want to save the changes?",
    //   showConfirmButton: false,
    //   showDenyButton: true,
    //   showCloseButton: true,
    //   showCancelButton: false,
    //   denyButtonText: `Don't save`,
    // }).then((result) => {
    //   if (result.isDenied) {
    //     props.history.push({
    //       pathname: "/adminPanel/SubAdmin_Management",
    //     });
    //   } else if (result.isCancelled) {
    //     Swal.fire("Changes are not saved", "", "info");
    //   }
    // });
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}

      <div className="page-content">
        <div className={classes.root}>
          <Paper>
            <div className={classes.paperPaddingRightLeft}>
              <div className="py-4">
                <Paper elevation={0} className={classNames(classes.addNewCategory)}>
                  <Button
                    onClick={() => {
                      handleBackButton();
                    }}
                  >
                    <ArrowBackIosIcon style={{ fontSize: "2rem" }} />
                  </Button>
                  <div className={classNames(classes.addNewCategoryHeading)}>
                    {" "}
                    <h3 className={classNames(classes.MarginControl)} style={{ marginBottom: "-0.5rem" }}>
                      {!state ? `ADD NEW SUB-ADMIN` : `EDIT SUB-ADMIN`}
                    </h3>
                  </div>
                </Paper>

                {/* new design */}
                <Paper
                  elevation={0}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ margin: "2rem 0 2rem 0" }}>
                    <Formik
                      validationSchema={validationSchema}
                      initialValues={{
                        name: get(state, "username", ""),
                        email: get(state, "email", ""),
                        phone: !state ? "" : state?.countryCode.replace(`+`, "") + get(state, "phone", ""),
                        file1: get(state, "profileImage", ""),
                        isInspector: state?.isInspector || false,
                      }}
                    
                      onSubmit={(values) => {
                        const isPanesDataFinalEmpty = PanesDataFinal.length < 1;

                        if (isPanesDataFinalEmpty) {
                          toast.error("Please Select at least one Module", {
                            position: toast.POSITION.TOP_RIGHT,
                          });
                        } else {
                          values.isInspector = isInspector;
                          if (state && state !== "") {
                            EditSubAdmin(values);
                          } else {
                            addNewSubAdmin(values);
                          }
                        }
                      }}
                    >
                      {({ values, setFieldValue, touched }) => (
                        <Form>
                          <Paper elevation={1} className="px-3 py-3">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                  gap: "1rem",
                                }}
                              >
                                <div style={{display:"flex", flexDirection:"column"}}>
                                  <label>Username:</label>
                                  <Field
                                    name="name"
                                    type="text"
                                    style={{
                                      width: 300,
                                      height: 35,
                                      borderRadius: 5,
                                      borderColor: "#d3d3d3",
                                      borderStyle: "solid",
                                      borderWidth: 1,
                                      paddingInlineStart: 10,
                                    }}
                                  />
                                  <KErrorMessage name="name" />
                                </div>
                                <div style={{display:"flex", flexDirection:"column"}}>
                                  <label>Email:</label>
                                    <Field
                                      name="email"
                                      type="email"
                                      autoComplete="off"
                                      style={{
                                        width: 300,
                                        height: 35,
                                        borderRadius: 5,
                                        borderColor: "#d3d3d3",
                                        borderStyle: "solid",
                                        borderWidth: 1,
                                        paddingInlineStart: 10,
                                      }}
                                      disabled={!state ? false : true}
                                    />
                                    <KErrorMessage name="email" />
                                </div>
                              </div>
                             
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{display:"flex", flexDirection:"column"}}>
                                  <label>Mobile Number:</label>
                                  <PhoneInput
                                    country={"us"}
                                    containerStyle={{}}
                                    countryCodeEditable={false}
                                    inputProps={{
                                      required: true,
                                    }}
                                    value={values.phone}
                                    onChange={(e, c) => {
                                      setFieldValue("phone", e);
                                      setdialCode(c.dialCode);
                                    }}
                                    name="phone"
                                    required
                                  />
                                  <KErrorMessage name="phone" />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  marginRight: 20,
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  color="primary"
                                  name="ALL"
                                  checked={isInspector}
                                  onChange={handleCheckboxChange}
                                />
                                <label style={{ marginBottom: "0px" }}>Is Inspector</label>
                              </div>

                              <label
                                style={{
                                  fontSize: "20px",
                                  display: "block",
                                  marginBottom:"0px"
                                }}
                              >
                                Module access given :
                              </label>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Select
                                  value={selectedTitles.map(title => ({ label: title, value: title }))}
                                  onChange={handleSelectChange}
                                  options={dropdownOptions.map(pane => ({ label: pane.panelName, value: pane.panelName }))}
                                  isMulti
                                  styles={{
                                    control: (provided) => ({ ...provided, width: 300 }),
                                  }}
                                />
                              </div>
                            </div>

                            <br />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                gap: "1.5%",
                                alignItems: "center",
                              }}
                            >
                              <label>Upload Image :</label>
                              <input
                                ref={fileRef}
                                name="file1"
                                hidden
                                type="file"
                                accept="image/png, image/jpeg , image/jpg"
                                onChange={async (e) => {
                                  let data = await uploadForm(e.target.files[0]);
                                  setFieldValue("file1", data);
                                  setProfileImagepath(data);
                                }}
                              />
                              <button
                                type="button"
                                onClick={() => {
                                  fileRef.current.click();
                                  setImageDelete(true);
                                }}
                                style={{
                                  borderRadius: "5px",
                                  backgroundColor: "#006FFF",
                                  color: "white",
                                  border: "none",
                                  padding: "5px",
                                }}
                              >
                                Upload
                              </button>
                              <KErrorMessage name="file1" />
                              {isSuccess
                                ? progress === 100
                                  ? setSpinnerDisplay(false)
                                  : setSpinnerDisplay(true)
                                : !state &&
                                  profileImagepath === "" && (
                                    <WallpaperIcon
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                    />
                                  )}
                              {
                                !state && profileImagepath !== "" && (
                                  <img
                                    src={profileImagepath}
                                    alt="..."
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                )
                              }
                              {state && values.file1 !== "" && (
                                <img src={values.file1} alt="..." style={{ width: "50px", height: "50px" }} />
                              )}
                            </div>
                            <br />
                          </Paper>

                          <br />
                          <br />

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              type="submit"
                              className="buttoncss"
                              style={{
                                borderRadius: "1.5rem",
                                border: "none",
                                fontSize: "1rem",
                                width: "15vw",
                                height: "5vh",
                                backgroundColor: "#006FFF",
                                color: "#fff",
                              }}
                            >
                              SAVE
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Paper>
              </div>
            </div>
          </Paper>
        </div>
        {spinnerDisplay ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,

              background: "rgba(0,0,0,0.7)",
              height: "100vh",
              width: "100vw",
            }}
          >
            <div style={{ marginTop: "20%", marginLeft: "20%" }}>
              {" "}
              <Spinner />
            </div>
            <div
              style={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: "bolder",
                textAlign: "center",
                marginTop: "10%",
                marginLeft: "15%",
              }}
            >
              {Math.ceil(progress)}%
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default AddEdit_SubAdmin;
