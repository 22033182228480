import * as actionTypes from "./actions";

const initialState = {
    deviceToken: "",
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DEVICE_TOKEN:
            return {
                ...state,
                deviceToken: action.updateDeviceToken,
            };

        default:
            return state;
    }
};

export default reducer;
